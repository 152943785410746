// src/App.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './App.css';

const App = () => {
  const [jsonData, setJsonData] = useState([]);
  const [searchTrait, setSearchTrait] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (!searchTrait) {
      setFilteredData([]);
      return;
    }

    const filterResults = jsonData.filter((item) =>
      item.attributes.some(
        (attr) =>
          attr.trait_type.toLowerCase() === searchTrait.label.toLowerCase() &&
          attr.value.toLowerCase() === searchTrait.value.toLowerCase(),
      ),
    );
    setFilteredData(filterResults);
  }, [searchTrait, jsonData]);

  const getDistinctTraits = () => {
    const traits = new Set();
    jsonData.forEach((item) =>
      item.attributes.forEach((attr) => traits.add(attr.trait_type)),
    );
    return Array.from(traits).map((trait) => ({ label: trait, value: trait }));
  };

  const getDistinctValues = (traitType) => {
    const values = new Set();
    jsonData.forEach((item) =>
      item.attributes.forEach(
        (attr) =>
          attr.trait_type === traitType && values.add(attr.value.toLowerCase()),
      ),
    );
    return Array.from(values).map((value) => ({ label: value, value }));
  };

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  const calculateRarity = (traitType, value) => {
    const itemCount = jsonData.reduce(
      (count, item) =>
        item.attributes.some(
          (attr) =>
            attr.trait_type === traitType && attr.value === value,
        ) ? count + 1 : count,
      0,
    );
    const rarity = (itemCount / jsonData.length) * 100;
    return rarity.toFixed(2);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const json = JSON.parse(e.target.result);
      setJsonData(json);
    };
    reader.readAsText(file);
  };

  return (
    <div className="App">
      <input
        type="file"
        accept=".json"
        onChange={handleFileUpload}
        style={{ display: 'block', marginBottom: '10px' }}
      />
      <Select
        options={getDistinctTraits()}
        onChange={(selectedOption) => {
          setSearchTrait(selectedOption);
        }}
      />
      {searchTrait && (
        <Select
          options={getDistinctValues(searchTrait.label)}
          onChange={(selectedOption) => {
            setSearchTrait({ ...searchTrait, value: selectedOption.value });
      }}
    />
  )}
  <div>
    Total count: {filteredData.length}
  </div>
  <div>
    {filteredData.map((item, index) => (
      <img
        key={index}
        src={item.image}
        alt={item.name}
        style={{
          width: '100px',
          height: '100px',
          margin: '10px',
          cursor: 'pointer',
        }}
        onClick={() => handleImageClick(item)}
      />
    ))}
  </div>
  {selectedImage && (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => setSelectedImage(null)}
    >
      <div>
        <img
          src={selectedImage.image}
          alt={selectedImage.name}
          style={{
            width: '200%',
            height: '200%',
          }}
        />
        <div className="trait-bg">
          {selectedImage.attributes.map((attr, index) => (
            <div key={index}>
              {attr.trait_type}: {attr.value} - Rarity: {calculateRarity(attr.trait_type, attr.value)}%
            </div>
          ))}
        </div>
      </div>
    </div>
  )}
</div>
);
};

export default App;
           

